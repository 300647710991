import React from 'react'
import { Navbar } from '../Main/Navbar/Navbar' ;
import { Footer } from '../Main/Footer/Footer';
import { Col, Container, Row } from 'react-bootstrap';
import  image1 from '../image/aboutpage1.png';
import  image2 from '../image/aboutpage2.png';
import  image3 from '../image/aboutpage3.png';
import  image4 from '../image/aboutpage4.png';
import { useTranslation } from 'react-i18next';
import './about.css';

export const About = () => {
  const { t } = useTranslation();

  return (
    <>
      <Navbar />
      <div className='about_page'>
        <Container>
          <h3 className='text'>{t('about.about_text')}</h3>
          <Row>
            <Col md="4">
              <img src={image1} alt='' />
            </Col>
            <Col md="4" >
              <img className='_center' src={image3} alt='' />
              <img src={image4} alt='' />
            </Col>
            <Col md="4">
              <img src={image2} alt='' />
            </Col>
          </Row>
          <h3 className='about_text'>{t('about.plan_name')}<span>{t('about.plan_company')}</span></h3>
          <p className='about_paragraph'>{t('about.plan_text')}</p>
        </Container>
      </div>
      <Footer />  
    </>
  )
}
