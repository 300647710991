import React from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Container, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Logo from '../../../image/logo1.png';
import Mamolin from '../../../image/mamolin.png';
import Sperolin from '../../../image/sperolin.png';
import './Slider.css';

export const Slider = () => {
    const { t } = useTranslation();
    return (
        <div className='sertificate_slider'>
            <Swiper modules={[Pagination]} slidesPerView={1} pagination={{"clickable": true}} loop={true}>
                <SwiperSlide>
                    <Container fluid="lg">
                        <Row >
                            <Col xl="6" md='6' className='about_title_paragraph'>
                                <img src={Mamolin} alt='' />
                            </Col>
                            <Col xl="6" md='6'>
                                <img src={Logo} alt='' />
                                <p className='about_paragraph'><span>{t('sertificate.mamalin')}</span>-{t('sertificate.paragraph1')}</p>
                            </Col>
                        </Row>
                    </Container>
                </SwiperSlide>
                <SwiperSlide>
                    <Container fluid="lg">
                        <Row>
                            <Col xl="6" md='6' className='about_title_paragraph'>
                                <img src={Sperolin} alt='' />
                            </Col>
                            <Col xl="6" md='6'>
                                <img src={Logo} alt='' />
                                <p className='about_paragraph'><span>{t('sertificate.sperolin')}</span>-{t('sertificate.paragraph2')}</p>
                            </Col>
                        </Row>
                    </Container>
                </SwiperSlide>
            </Swiper>
        </div>
    );
};
