import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Container } from 'react-bootstrap';
import ProductComp from './ProductComp';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const Product = () => {

    const { lang } = useSelector(state => state.nowLanguage)


    const { t } = useTranslation()
    const loader = (
        <div style={{width: '100%', textAlign: 'center'}}>
            loading....
        </div>
    )
    const errorD = (
        <div style={{width: '100%', textAlign: 'center'}}>
            ERROR!!!
        </div>
    )

    const [allProducts, setAllProducts] = useState([]);
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    const getAllProducts = () => {
        axios.get(`/api/products?skip=1&limit=4`).then(res => {
            setAllProducts(res.data.products)
            setLoading(false)
            setError(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
            setError(true)
        })
    }
    
    useEffect(() => {
        getAllProducts()
    },[])

    return (
        <>
            <div className='products'>
                <Container>
                    <Link to="/product" className='text'>{t('products.text')}</Link>
                    {loading ? loader : null}
                    {error ? errorD : null}
                    <Row className='justify-content-md-center'>
                        {
                            allProducts && Array.isArray(allProducts) ? allProducts.map((product, index) => {
                                let description = ''

                                for(let i = 0; i < product.descr[0][`${lang}`].length; i ++) {
                                    if (i < 50) {
                                        description += product.descr[0][`${lang}`][i];
                                    }
                                    else {
                                        break;
                                    }
                                }
                                return (
                                    <ProductComp key={index} product={product} description={`${description}...`} />
                                )
                            }): ""
                        }
                    </Row> 
                    <Link to='/product'><button className='btn_pro mt-5'>{t('products.more_btn')}</button></Link>    
                </Container>
            </div>
        </>
    )
}
