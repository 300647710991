import React from 'react'
import { Slider } from './swiper/Slider';
import { Keyframes } from "../../UI/Logo/keyframes";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper';
import Sertificate1 from '../../image/cert1.jpg';
import Sertificate2 from '../../image/cert2.jpg';
import Sertificate3 from '../../image/cert3.jpg';
import Sertificate4 from '../../image/cert4.jpg';
import Sertificate5 from '../../image/cert5.jpg';
import Sertificate6 from '../../image/cert6.jpg';
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import "swiper/css/navigation";

export const Sertificat = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className='sertificate' id='certificate'>
        <h3 className='text'>{t('sertificate.cert_text')}</h3>
        <Swiper modules={[Navigation]} loop navigation={true} className="mySwiper"
          breakpoints={{
            1600:{ slidesPerView: 4, spaceBetween: 80 },
            1200:{ slidesPerView: 4, spaceBetween: 60 },
            992: { slidesPerView: 3, spaceBetween: 60 },
            786: { slidesPerView: 2, spaceBetween: 60 },
            276: { slidesPerView: 2, spaceBetween: 30 }
          }}>
          <SwiperSlide>
            <img src={Sertificate1} alt='' />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Sertificate2} alt='' />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Sertificate3} alt='' />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Sertificate4} alt='' />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Sertificate5} alt='' />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Sertificate6} alt='' />
          </SwiperSlide>
        </Swiper>
      </div>
      <Keyframes />
      <Slider />
    </>
  )
}