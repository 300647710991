import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const ProductComp = (props) => {

    const { lang } = useSelector(state => state.nowLanguage)

    const { t } = useTranslation()
    const { product, description } = props

    const [ratingValue, setRatingValue] = useState(0)
    const handleRating = (rate) => {
        setRatingValue(rate)
    }

    return (
        <Col xl="3" lg="5" md="6" sm="6">
            <div className='cards'>
                <div className='cards_image' style={{backgroundImage: `url(${product.photo})`}}></div>
                <h4>{product.name[0][`${lang}`]}</h4>
                <p>{description}</p>
                <Rating onClick={handleRating} ratingValue={ratingValue} allowHover={false} size={27} />
                <div className='buttons'>
                    <a href='/#contact' className='link1'>
                        <button className='btn_pro'>{t('products.sale')}</button>
                    </a>
                    <Link to={`/product/${product._id}`} className='link1'>
                        <button className='btn_pro'>{t('products.ready')}</button>
                    </Link>
                </div>
            </div>
        </Col>
    )
}

export default ProductComp
