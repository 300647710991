import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import CenterImage from '../../image/about_image_center.png';
import CenterTop from '../../image/about_image_top.png';
import CenterBottom from '../../image/about_image_bottom.png';
import { useTranslation } from 'react-i18next';
import './swiper/index.css';
import logo from '../../../Components/image/logo.png'

export const About = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className='about'>
                <Container fluid="lg">
                    <h3 className='about_text'>{t('about.about_text')}</h3>
                    <Row>
                        <Col md="6" sm="10" className='about_images'>
                            <img className='image_top' src={CenterTop} alt='' />
                            <img className='image_center' src={CenterImage} alt='' />
                            <img className='image_bottom' src={CenterBottom} alt='' />
                        </Col>
                        <Col>
                            <img src={logo} alt='' className='logo' />
                            {/* <h3 className='about_text'>{t('about.plan_name')}<span>{t('about.plan_company')}</span></h3> */}
                            <p className='about_paragraph'>{t('about.plan_text')}</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
