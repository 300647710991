import React from 'react';
import LogoPlan_baby from '../../image/logo1.png';
import LogoDoraline from '../../image/doraline.png';
import kirchLogo from '../../image/kirch-logo.png';
import sternLogo from '../../image/stern-logo.png';
import { useTranslation }  from 'react-i18next';

export const Hamkor = () => {
    const { t } = useTranslation();
    return (
        <div className='hamkor' id='partners'>
            <h3 className='text'>{t('partners.partners')}</h3>
            <section className="slide-option">
                <div id="infinite" className="highway-slider">
                    <div className="highway-barrier">
                        <ul className="highway-lane">
                            <li className="highway-car"><img src={LogoPlan_baby} alt="" /></li>
                            <li className="highway-car"><img src={LogoDoraline} alt="" /></li>
                            <li className="highway-car"><img src={kirchLogo} alt="" /></li>
                            <li className="highway-car"><img src={sternLogo} alt="" /></li>
                            <li className="highway-car"><img src={LogoPlan_baby} alt="" /></li>
                            <li className="highway-car"><img src={LogoDoraline} alt="" /></li>
                            <li className="highway-car"><img src={kirchLogo} alt="" /></li>
                            <li className="highway-car"><img src={sternLogo} alt="" /></li>
                            <li className="highway-car"><img src={LogoPlan_baby} alt="" /></li>
                            <li className="highway-car"><img src={LogoDoraline} alt="" /></li>
                            <li className="highway-car"><img src={kirchLogo} alt="" /></li>
                            <li className="highway-car"><img src={sternLogo} alt="" /></li>
                            
                            <li className="highway-car"><img src={LogoPlan_baby} alt="" /></li>
                            <li className="highway-car"><img src={LogoDoraline} alt="" /></li>
                            <li className="highway-car"><img src={kirchLogo} alt="" /></li>
                            <li className="highway-car"><img src={sternLogo} alt="" /></li>
                            <li className="highway-car"><img src={LogoPlan_baby} alt="" /></li>
                            <li className="highway-car"><img src={LogoDoraline} alt="" /></li>
                            <li className="highway-car"><img src={kirchLogo} alt="" /></li>
                            <li className="highway-car"><img src={sternLogo} alt="" /></li>
                            <li className="highway-car"><img src={LogoPlan_baby} alt="" /></li>
                            <li className="highway-car"><img src={LogoDoraline} alt="" /></li>
                            <li className="highway-car"><img src={kirchLogo} alt="" /></li>
                            <li className="highway-car"><img src={sternLogo } alt="" /></li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    )
}
