import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import { useTranslation } from 'react-i18next';
import YouTube from 'react-youtube';

import 'swiper/css';
import 'swiper/css/pagination';

export const Slide = () => {
    const { t } = useTranslation();

    return (
        <div className='slider'>
            <h3 className='text'>{t('about.slide')}</h3>
            <Swiper modules={[Pagination, Navigation]} spaceBetween={35} pagination={{ clickable: true }} navigation={true} centeredSlides={true} loop={true} breakpoints={{1500: {slidesPerView: 4},1200: {slidesPerView: 3},586: {slidesPerView: 1.5},300: {slidesPerView: 1}}}>
                <SwiperSlide>
                    <YouTube className='video_youtube' videoId='AeZVVWCFvYs' rel='0'/>
                </SwiperSlide>
                <SwiperSlide>
                    <YouTube className='video_youtube' videoId='RxHuVEy_9Os' rel='0'/>
                </SwiperSlide>
                <SwiperSlide>
                    <YouTube className='video_youtube' videoId='5LS2KIeVJLY' rel='0'/>
                </SwiperSlide>
                <SwiperSlide>
                    <YouTube className='video_youtube' videoId='RMygPp2QyKY' rel='0'/>
                </SwiperSlide>
                <SwiperSlide>
                    <YouTube className='video_youtube' videoId='2kso7Dvq_4Y' rel='0'/>
                </SwiperSlide>
            </Swiper>
        </div>
    );
}
