import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Navbar } from '../Main/Navbar/Navbar';
import { Footer } from '../Main/Footer/Footer'
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './product.css';


const OneProduct = () => {

    const { lang } = useSelector(state => state.nowLanguage)

    const { t } = useTranslation()
    const [oneProduct, setOneProduct] = useState([])
    const id = useParams().id

    useEffect(() => {
        axios.get(`/api/products/product/${id}`).then(res => {
            setOneProduct(res.data.product)

        }).catch(err => {
            console.log(err)
        })

    }, [id])

    return (
        <>
            <Navbar/>
            <div className='one_product'>
                <Container>
                    <h3 className='text start'>{oneProduct.name ? oneProduct.name[0][`${lang}`] : ''}</h3>
                    <Row>
                        <Col lg='4' md='5' className='one_product_image'>
                            <img src={`/${oneProduct.photo ? oneProduct.photo : ''}`} alt='' />
                        </Col>
                        <Col>
                            <h4 className='one_product_text'>{t('products.tarkibi')}</h4>
                            <p className='one_product_title'>{oneProduct.content ? oneProduct.content[0][`${lang}`] : ''}</p>
                            <h4 className='one_product_text'>{t('products.tafsif')}</h4>
                            <p className='one_product_title'>{oneProduct.descr ? oneProduct.descr[0][`${lang}`] : ''}</p>
                            <h4 className='one_product_text'>{t('products.ishlatish')}</h4>
                            <p className='one_product_title'>{oneProduct.usage ? oneProduct.usage[0][`${lang}`] : ''}</p>
                            <a href='/#contact'>
                                <button className='one_btn my-4'>{t('navbar.contact')}</button>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </>
    ) 
}

export default OneProduct
