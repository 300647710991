import React, { useState } from 'react';
import messenger_phone from '../../image/messengerphone.png';
import message from '../../image/message_image.png';
import Konsultant from '../../image/konsultant.png';
import MyChat from './Chat_body/MyChat';
import { IoMdClose, IoMdSend }  from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import './message.css';


export const Message = () => {
    const { t } = useTranslation();
    const [success, setSuccess] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [ firstName, setFirstName ] = useState('')
    const [ phone, setPhone ] = useState('')
    const [open, setOpen] = useState(true)
    const [chatopen, setChatopen] = useState(false)
    const chatopenmessage = chatopen ? "messenger_chat active" : "messenger_chat"
    const [posts, setPosts] = useState([{id: 1, message: `${t('contact.question')}`}])
    const createPost = (newPost) => {
        setPosts([...posts, newPost])
    }

    const [post, setPost] = useState({message:''})

    const addPost = (e) => {
        e.preventDefault()
        let Messages = post.message
        let url = `https://api.telegram.org/bot5154694871:AAFhU7Mz1ZMZ_XxHQn7KQ1TX_zEsO2wxdTc/sendMessage?chat_id=-623583176&text=${Messages}&parse_mode=html`;
        let apibot = new XMLHttpRequest();
        apibot.open("GET", url, true);
        const newPost = {
            ...post,
            id: Date.now()
        }
        createPost(newPost)

        setPost({message: ""})

        if(posts.length === 1) {
            setOpen(false)
            setDisabled(true)
        }
    }

    const sendMessage = (e) => {
        e.preventDefault();
        let message = `<b>📬 Maslatah olsih</b>%0A<b>👤 Ismi: </b><i>${firstName}</i>%0A<b>📞 Tel raqami: </b><i>${phone}</i>%0A`;
        let url = `https://api.telegram.org/bot5154694871:AAFhU7Mz1ZMZ_XxHQn7KQ1TX_zEsO2wxdTc/sendMessage?chat_id=-623583176&text=${message}&parse_mode=html`;
        let apibot = new XMLHttpRequest();
        apibot.open("GET", url, true);
        apibot.send();
        setFirstName('')
        setPhone('')
        setOpen(true)
        if (apibot.send) {
            setDisabled(false)
            setSuccess(!success)
        }
    }


    return (
        <>
            <a href='tel:+996701170701' className='message_message'>
                <img src={messenger_phone} alt='messenger icon' />
            </a>
            <div className='message_tel'>
                <img src={message} alt='messenger icon' onClick={() => setChatopen(!chatopen)} />
            </div>

            <div className={chatopenmessage} >
                <div className='chat_head'>
                    <div className='avatar'>
                        <img src={Konsultant} alt='' />
                        <h3>Консультант</h3>
                    </div>
                    <IoMdClose  onClick={() => setChatopen(!chatopen)} className='closeIcons' />
                </div>
                <MyChat 
                    posts={posts} 
                    open={open} 
                    setOpen={setOpen} 
                    addPost={addPost} 
                    sendMessage={sendMessage} 
                    setFirstName={setFirstName}
                    firstName={firstName}
                    phone={phone}
                    setPhone={setPhone}
                    success={success}
                    setSuccess={setSuccess}
                />
                <form className='chat_send'>
                    <textarea 
                        type='text' 
                        placeholder={t('contact.yoz')} 
                        value={post.message}
                        onChange={(e) => setPost({...post, message:e.target.value})}
                    />
                    <button onClick={addPost} disabled={disabled} ><IoMdSend /></button>
                </form>
            </div>
        </>
  )
};