import React from 'react';
import LoadingImg from '../image/loading.png';
import './loader.css'

const Loader = () => {
  return (
    <div className='loaders'>
        <img src={LoadingImg} alt="" />
    </div>
  )
}

export default Loader
