import React from 'react';
import { Col, Row } from 'react-bootstrap';
import BabyImage from '../../image/babyimage.png';
import Babybg from '../../image/baby.png';
import { useTranslation } from 'react-i18next';
import './baby.css';

export const Baby = () => {
    const { t } = useTranslation();

    return (
        <div className='baby'>
            <Row>
                <Col className='baby_image' md='6'>
                    <img src={Babybg}  alt='' />
                    <h3 className='baby_text'>{t('headAnime.baby_text')}</h3>
                </Col>
                <Col className='baby_center'>
                    <h3 className='baby_text'>{t('headAnime.baby_question')}</h3>
                    <img src={BabyImage} alt='' className="baby_" />
                    <a href="/#contact">
                        <button className='btn_pdf'>{t('products.pdf')}</button> 
                    </a>
                </Col>
            </Row>
        </div>
  )
};
