import React from 'react';
import ReactInputMask from 'react-input-mask';
import { useTranslation } from 'react-i18next';
import './form.css';

export default function MyForm({sendMessage, firstName, setFirstName, phone, setPhone, open, setOpen}) {

    const { t } = useTranslation();

    const chat_body_form = open ? "chat_body_form active" : "chat_body_form"

    return (
        <form onSubmit={sendMessage} className={chat_body_form}>
            <input 
                type='text' 
                placeholder={`${t('contact.name')}`} 
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
            />
            <ReactInputMask 
                id="phone" 
                required mask="+\9\9\6 (999) 999-999" 
                placeholder={`${t('contact.number')}`} 
                value={phone} 
                onChange={(e) => setPhone(e.target.value)}
            />
            <button className='btn btn-primary w-100 mt-3' onClick={() => setOpen(open)} >{t('contact.pol')}</button>
        </form>
    )
}
