import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import   { useTranslation } from 'react-i18next';
import number1 from '../../../image/1.png';
import number2 from '../../../image/2.png';
import number3 from '../../../image/3.png';
import number4 from '../../../image/4.png';
import number5 from '../../../image/5.png';
import number6 from '../../../image/6.png';
import number7 from '../../../image/7.png';
import number8 from '../../../image/8.png';
import './index.css';

export const Mans = () => {
    const { t } = useTranslation();
    const [posts] = useState([
        {id: 1, image: number1, name: t('products.mans_name1'), title: t('products.mans_paragraph1')},
        {id: 2, image: number2, name: t('products.mans_name2'), title: t('products.mans_paragraph2')},
        {id: 3, image: number3, name: t('products.mans_name3'), title: t('products.mans_paragraph3')},
        {id: 4, image: number4, name: t('products.mans_name4'), title: t('products.mans_paragraph4')},
        {id: 5, image: number5, name: t('products.mans_name5'), title: t('products.mans_paragraph5')},
        {id: 6, image: number6, name: t('products.mans_name6'), title: t('products.mans_paragraph6')},
        {id: 7, image: number7, name: t('products.mans_name7'), title: t('products.mans_paragraph7')},
        {id: 8, image: number8, name: t('products.mans_name8'), title: t('products.mans_paragraph8')}

    ])
    return (
        <>
            <div className='mans_title'>
                <Container>
                    <Row>
                        {posts.map(post => {
                            return (
                                <Col  xl='3' lg="4" md="5" sm="6" xs="12"  key={post.id} className='text_title_group'>
                                    <img className='number_image' src={post.image} alt="" />
                                    <h3 className='post_name'>{post.name}</h3>
                                    <p className='post_title'>{post.title}</p>
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            </div>
        </>
    );
};
