import {combineReducers, createStore} from 'redux'
import languageReducers from './reducers/languageReducers'
import {composeWithDevTools} from 'redux-devtools-extension'

const rootReducers = combineReducers({
    nowLanguage: languageReducers
})

const store = createStore(rootReducers, composeWithDevTools())

export default store

