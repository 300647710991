import {LANGUAGE_TOP} from './Types'


export const lanProduct = (langs) => {

    return {
        type: LANGUAGE_TOP,
        payload: langs
    }
}
