import {LANGUAGE_TOP} from '../action/Types'
import cookie from 'js-cookie';

const initialLang = cookie.get('i18next') || 'Kg';


const initilState = {
    lang: initialLang
}

export const languageReducers = (state = initilState, action) => {

    switch (action.type) {
        case LANGUAGE_TOP:
            
        return {
            lang: action.payload
        }
    
        default:
            return state
    }
}


export default languageReducers