import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AiOutlineMenu } from 'react-icons/ai';
import { RiCloseFill } from 'react-icons/ri';
import Logo from '../../image/logo.png';
import  { useTranslation } from 'react-i18next';

export const Navbar = ({changeLan}) => {
    const [count, setCount] = useState(0)
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [close, setClose] = useState(true);
    const Languages = open ? "languages" : " languages active";
    const CloseHam = close ?  "hamburger_menu active" : "hamburger_menu";

    return (
        <>
            <div className='navbar'>
                <Container fluid="lg" md="auto">
                    <div className='navbar_nav'>
                        <div className='nav_image'>
                            <Link to="/">
                                <img src={Logo} alt="plan-baby" />
                            </Link>
                        </div>
                        <ul className='respons'>
                            <li>
                                <Link to="/" className='navbar-link'>{t('navbar.home')}</Link>
                            </li>
                            <li>
                                <Link to="/product" className='navbar-link'>{t('navbar.products')}</Link>
                            </li>
                            <li>
                                <Link to="/about" className='navbar-link'>{t('navbar.about')}</Link>
                            </li>
                            <li>
                                <a href="/#certificate" className='navbar-link'>{t('navbar.srtificate')}</a>
                            </li>
                            <li>
                                <a href="/#partners" className='navbar-link'>{t('navbar.partners')}</a>
                            </li>
                            <li>
                                <a href="/#contact" className='navbar-link'>{t('navbar.contact')}</a>
                            </li>
                            {/* <li>
                                <Link to="/blog" className='navbar-link' onClick={() => setCount(count + 1)}>{t('navbar.blog')}</Link>
                            </li> */}
                        </ul>
                        <div className='lang'>
                            <AiOutlineMenu className='nav_icon' onClick={() => setClose(!close)} />
                            <div className='language' open={open} onClick={() => setOpen(!open)}>
                                <h4>{t('navbar.lang')}</h4>
                            </div>
                        </div>
                        <div className={Languages}>
                            <h4 onClick={()=> {changeLan('Uz'); setOpen(false)}}>Uz</h4>
                            <h4 onClick={()=> {changeLan('Ru'); setOpen(false)}}>Ru</h4>
                            <h4 onClick={()=> {changeLan('En'); setOpen(false)}}>En</h4>
                            <h4 onClick={()=> {changeLan('Kg'); setOpen(false)}}>Kg</h4>
                        </div>
                        <div className={CloseHam}>
                            <RiCloseFill className='close' onClick={() => setClose(!close)} />
                            <ul>
                                <li>
                                    <Link to="/" className='navbar-link' onClick={() => setClose(!close)}>{t('navbar.home')}</Link>
                                </li>
                                <li>
                                    <Link to="/product" className='navbar-link' onClick={() => setClose(!close)}>{t('navbar.products')}</Link>
                                </li>
                                <li>
                                    <Link to="/about" className='navbar-link' onClick={() => setClose(!close)}>{t('navbar.about')}</Link>
                                </li>
                                <li>
                                    <a href="/#certificate" className='navbar-link' onClick={() => setClose(!close)}>{t('navbar.srtificate')}</a>
                                </li>
                                <li>
                                    <a href="/#partners" className='navbar-link' onClick={() => setClose(!close)}>{t('navbar.partners')}</a>
                                </li>
                                <li>
                                    <a href="/#contact" className='navbar-link' onClick={() => setClose(!close)}>{t('navbar.contact')}</a>
                                </li>
                                {/* <li>
                                    <Link to="/blog" className='navbar-link' onClick={() => {setCount(count + 1)}}>{t('navbar.blog')}</Link>
                                </li>                   */}
                            </ul>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}
