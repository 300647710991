import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import "./index.css";  

export const Treatment = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className='treatment'>
                <Container>
                    <h3 className='title_text'>{t('products.treatment_text')}</h3>
                    <p className='title_paragraph'>{t('products.treatment_paragraph')}</p>
                </Container>
            </div>
        </>
    );
};
