import React from 'react';
import MyForm from '../Form/MyForm';
import { useTranslation } from 'react-i18next';
import './chat.css';

export default function MyChat({posts, open, setOpen, firstName, setFirstName, phone, setPhone, sendMessage, success}) {
    const { t } = useTranslation();
    const SuccessMessage = success ? "success_message active" : "success_message"
    return (
        <div className='chat_body'>
            <MyForm
                sendMessage={sendMessage}
                firstName={firstName}
                setFirstName={setFirstName}
                phone={phone}
                setPhone={setPhone}
                open={open}
                setOpen={setOpen}
            /> 
            <h3 className={SuccessMessage}>{t('contact.h3')}</h3>       
            {posts.map((post, index) => (
                <div className="chat_text" key={index}>
                    <h3>{post.message}</h3>
                </div>
            ))}
        </div>
    )
}
