import React, { useState } from "react";
import Main from "./Components/Main/Main";
import { About } from "./Components/About/About";
import { Products } from "./Components/Products/Products";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { lanProduct } from "./redux/action/languageAction";
import { useDispatch } from "react-redux";
import OneProduct from "./Components/Products/OneProduct";
import i18next from "i18next";
import Loader from "./Components/loader/Loader";

function App() {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(true);
  const changeLan = (lang) => {
    i18next.changeLanguage(lang);
    dispatch(lanProduct(lang));
  };

  setTimeout(() => {
    setLoad(false);
    document.body.style.overflow = "unset";
  }, 2000);

  return (
    <div className="App">
      <BrowserRouter>
        {load ? <Loader /> : null}
        <Routes>
          <Route path="/" element={<Main changeLan={changeLan} />} />
          <Route path="/about" element={<About />} />
          <Route path="/product" element={<Products />} />
          <Route path="/product/:id" element={<OneProduct />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
