import React from 'react';
import Logo from '../../image/logo1.png';
import './keyframes.css';

export const Keyframes = () => {
    return (
        <>
            <section className="slide-option">
                <div id="infinite" className="highway-slider">
                    <div className="highway-barrier">
                        <ul className="highway-lane">
                            <li className="highway-car"><img src={Logo} alt="" /></li>
                            <li className="highway-car"><img src={Logo} alt="" /></li>
                            <li className="highway-car"><img src={Logo} alt="" /></li>
                            <li className="highway-car"><img src={Logo} alt="" /></li>
                            <li className="highway-car"><img src={Logo} alt="" /></li>
                            <li className="highway-car"><img src={Logo} alt="" /></li>
                            <li className="highway-car"><img src={Logo} alt="" /></li>
                            <li className="highway-car"><img src={Logo} alt="" /></li>
                            <li className="highway-car"><img src={Logo} alt="" /></li>
                            <li className="highway-car"><img src={Logo} alt="" /></li>
                            <li className="highway-car"><img src={Logo} alt="" /></li>
                            <li className="highway-car"><img src={Logo} alt="" /></li>
                            
                            <li className="highway-car"><img src={Logo} alt="" /></li>
                            <li className="highway-car"><img src={Logo} alt="" /></li>
                            <li className="highway-car"><img src={Logo} alt="" /></li>
                            <li className="highway-car"><img src={Logo} alt="" /></li>
                            <li className="highway-car"><img src={Logo} alt="" /></li>
                            <li className="highway-car"><img src={Logo} alt="" /></li>
                            <li className="highway-car"><img src={Logo} alt="" /></li>
                            <li className="highway-car"><img src={Logo} alt="" /></li>
                            <li className="highway-car"><img src={Logo} alt="" /></li>
                            <li className="highway-car"><img src={Logo} alt="" /></li>
                            <li className="highway-car"><img src={Logo} alt="" /></li>
                            <li className="highway-car"><img src={Logo} alt="" /></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}
