import React from 'react';
import { Navbar } from '../Main/Navbar/Navbar';
import { Footer } from '../Main/Footer/Footer';
import AllProducts from './AllProducts';

export const Products = () => {
  return (
    <>
        <Navbar />
        <AllProducts />
        <Footer />
    </>
  );
};
