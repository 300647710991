import React from 'react';
import { Container } from 'react-bootstrap';
import address from '../../image/address.png';
import email from '../../image/email.png';
import call from '../../image/call.png';
import ReactInputMask from 'react-input-mask';
import { useTranslation } from 'react-i18next';

export const Contact = ({
    sendMessage, 
    fullname, 
    phone, 
    viloyat, 
    tuman, 
    mahalla,
    kocha,
    sperolinNum,
    mamolinNum,
    anefroNum,
    setFullname, 
    setPhone, 
    setViloyat, 
    setTuman,
    setMahalla,
    setKocha,
    setSperolinNum,
    setMamolinNum,
    setAnefroNum,
    allSum,
    sperolinPrice,
    mamolinPrice,
    anefroPrice,
    state, 
    setState,
    success
}) => {
    const { t } = useTranslation();

    const select = (
        <>
            <div className='selected-drugs'>
                <div className='one-drug'>
                    <p>Sperolin</p>
                    <span onClick={() => sperolinNum > 0 ? setSperolinNum(sperolinNum - 1) : null} >-</span>
                    <input type='text' readOnly value={sperolinNum} />
                    <span onClick={() => setSperolinNum(sperolinNum + 1)} >+</span>
                    <div>{sperolinPrice} сом</div>
                </div>
                
                <div className='one-drug'>
                    <p>Mamolin</p>
                    <span onClick={() => mamolinNum > 0 ? setMamolinNum(mamolinNum - 1) : null} >-</span>
                    <input type='text' readOnly value={mamolinNum} />
                    <span onClick={() => setMamolinNum(mamolinNum + 1)} >+</span>
                    <div>{mamolinPrice} сом</div>
                </div>
                <span className='jami'>{t('contact.jami')}<span>{allSum}</span>сом</span>
            </div>
            <button onClick={() => setState('buy')} >{t('contact.skip')}</button>
        </>
    )

    const buy = (
        <form onSubmit={sendMessage}>
            <div className='qator'>
                <div className='input-label'>
                    <label>{t('contact.name')}</label>
                    <input type='text' required value={fullname} onChange={(e) => setFullname(e.target.value)} />
                </div>
                <div className='input-label'>
                    <label>{t('contact.number')}</label>
                    <ReactInputMask id="phone" required mask="+\9\9\6 (999) 999-999" value={phone} onChange={(e) => setPhone(e.target.value)} />
                </div>
            </div>
            <div className='qator'>
                <div className='input-label'>
                    <label>{t('contact.country')}</label>
                    <input type='text' value={viloyat} onChange={(e) => setViloyat(e.target.value)} />
                </div>
                <div className='input-label'>
                    <label>{t('contact.tuman')}</label>
                    <input type='text' required value={tuman} onChange={(e) => setTuman(e.target.value)} />
                </div>
            </div>
            <div className='qator'>
                <div className='input-label'>
                    <label>{t('contact.mahalla')}</label>
                    <input type='text'  value={mahalla} onChange={(e) => setMahalla(e.target.value)} />
                </div>
                <div className='input-label'>
                    <label>{t('contact.street')}</label>
                    <input type='text'  value={kocha} onChange={(e) => setKocha(e.target.value)} />
                </div>
            </div>
            <div className='selected-datas my-3'>
                {t('contact.you')}<strong>{sperolinNum > 0 ? `${sperolinNum} даана Sperolin, ` : null} {mamolinNum > 0 ? `${mamolinNum} даана Mamolin, ` : null} {anefroNum > 0 ? `${anefroNum} даана Anefro, ` : null} </strong> {sperolinNum === 0 && mamolinNum === 0 && anefroNum === 0 ? t('contact.false') : t('contact.true')}
                <br />
                {t('contact.jami')}: <strong>{allSum} сом</strong>
                <br />
                {t('contact.please')}
            </div>
            <span className='prev' onClick={() => setState('select')} >{t('contact.again')}</span>
            <button type='submit'>{t('products.sale')}</button>
        </form>
    )

    const selectA = state === 'select' ? select : null
    const buyA = state === 'buy' ? buy : null
    const successA = state === 'success' ? success : null

    return (
        <Container>
            <div className='contact' id='contact'>
                <div className='left'>
                    <h1>{t('contact.contact')}</h1>
                    <div>
                        <img src={address} alt='adres' />
                        <h3>{t('contact.manzil')}</h3>
                        <p>{t('contact.addres')}</p>
                    </div>
                    <div>
                        <img src={email} alt='adres' />
                        <h3>Email</h3>
                        <p>planbaby@gmail.com</p>
                    </div>
                    <div>
                        <img src={call} alt='adres' />
                        <h3>Call-center</h3>
                        <p>+996(701) 170-701</p>
                    </div>
                    <iframe title='address' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3033.480959890849!2d72.80932176091375!3d40.50885925217984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xcef1a72bc14e447e!2zNDDCsDMwJzM1LjIiTiA3MsKwNDgnMzQuNSJF!5e0!3m2!1sen!2s!4v1645257754220!5m2!1sen!2s" allowFullScreen="" loading="lazy"></iframe>
                </div>
                <div className='right'>
                    <div className='top'>
                        <span>{t('contact.expert')}</span>
                    </div>
                    <h1>{t('products.sale')}</h1>
                    {selectA}
                    {buyA}
                    {successA}
                </div>
            </div>
        </Container>
    )
}
