import React, { useState } from 'react';
import { Navbar } from './Navbar/Navbar';
import { Header } from './Header/Header';
import { Baby } from './Baby/Baby';
import { Product } from './Products/Product';
import { Mans } from './Products/product_title/Mans';
import { Slide } from './About/swiper/Slide';
import  { Treatment } from './Products/Treatment/Treatment';
import { About } from './About/About';
import { Sertificat } from './Sertificat/Sertificat';
import { Hamkor } from './Hamkorlar/Hamkor';
import { Contact } from './Contact/Contact';
import { Footer } from './Footer/Footer';
import { Message } from '../UI/Message/Message';
import './style/index.css';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';

const Main = ({ changeLan }) => {

    const { t } = useTranslation();

    const [ state, setState ] = useState('select')

    const [ sperolinNum, setSperolinNum ] = useState(0)
    const sperolinPrice = 4250 * sperolinNum

    const [ mamolinNum, setMamolinNum ] = useState(0)
    const mamolinPrice = 4250 * mamolinNum

    const [ anefroNum, setAnefroNum ] = useState(0)
    const anefroPrice = 52000 * anefroNum

    const [ fullname, setFullname ] = useState('')
    const [ phone, setPhone ] = useState('')
    const [ viloyat, setViloyat ] = useState('')
    const [ tuman, setTuman ] = useState('')
    const [ mahalla, setMahalla ] = useState('')
    const [ kocha, setKocha ] = useState('')

    const sendMessage = (e) => {
        e.preventDefault();
        let token = "5154694871:AAFhU7Mz1ZMZ_XxHQn7KQ1TX_zEsO2wxdTc";
        let chatID = "-623583176";
        let message = `<b>📬 Онлайн сатуу</b>%0A<b>👤 аты: </b><i>${fullname}</i>%0A<b>📞 Тел номери: </b><i>${phone}</i>%0A <b>🏛 Провинция: </b><i>${viloyat}</i>%0A <b>🏙 Туман: </b><i>${tuman}</i>%0A <b>⛪ Махалла: </b><i>${mahalla}</i> %0A <b>🏡 Көчө: </b><i>${kocha}</i> %0A <b>💊 Сперолин: </b><i>${sperolinNum} та ${sperolinPrice} сом</i> %0A <b>💊 Мамолин: </b><i>${mamolinNum} та ${mamolinPrice} сом</i> %0A <b>💊 Anefro: </b><i>${anefroNum} та ${anefroPrice} сом</i> %0A <b>🛒 Жалпы баасы: </b><i>${allSum} сом</i>`;
        let url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatID}&text=${message}&parse_mode=html`;
        let apibot = new XMLHttpRequest();
        apibot.open("GET", url, true);
        apibot.send();
        setState('success')
        setFullname('')
        setPhone('')
        setViloyat('')
        setTuman('')
        setMahalla('')
        setKocha('')
    }
    

    const allSum = sperolinPrice + mamolinPrice + anefroPrice

    const success = (
        <div className='success'>
            <h3>{t('contact.end')}</h3>
        </div>
    )

    return (
        <>
            
            <Navbar changeLan={changeLan} />
            {/* <Header/> */}
            <Baby />
            <Product />
            <Slide />
            <Treatment />
            <About />
            <Mans />
            <Sertificat />
            <Hamkor />
            <Contact 
                sendMessage={sendMessage} 
                setFullname={setFullname} 
                setPhone={setPhone}
                setViloyat={setViloyat}
                setTuman={setTuman}
                setMahalla={setMahalla}
                setKocha={setKocha}
                setSperolinNum={setSperolinNum}
                setMamolinNum={setMamolinNum}
                setAnefroNum={setAnefroNum}
                setState={setState}
                fullname={fullname} 
                phone={phone}
                viloyat={viloyat}
                tuman={tuman}
                mahalla={mahalla}
                kocha={kocha}
                sperolinNum={sperolinNum}
                mamolinNum={mamolinNum}
                anefroNum={anefroNum}
                allSum={allSum}
                sperolinPrice={sperolinPrice}
                mamolinPrice={mamolinPrice}
                anefroPrice={anefroPrice}
                state={state}
                success={success}
            />
            <Footer/>
            <Message/>
        </>
    )
}

export default Main;