import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';
import Logo from '../../image/logo.png';
import insta from '../../image/in.png';
import tele from '../../image/tg.png';

export const Footer = () => {
    const { t } = useTranslation();

    return (
        <div className='footer'>
            <Container fluid="lg" md="auto">
                <Row>
                    <Col md='2' sm='4'>
                        <Link to="/">
                            <img src={Logo} alt="plan-baby" className='logo' />
                        </Link>
                    </Col>
                    <Col md='10'>
                        <ul>
                            <li>
                                <Link to="/" className='navbar-link' >{t('navbar.home')}</Link>
                            </li>
                            <li>
                                <Link to="/product" className='navbar-link' >{t('navbar.products')}</Link>
                            </li>
                            <li>
                                <Link to="/about" className='navbar-link' >{t('navbar.about')}</Link>
                            </li>
                            <li>
                                <a href="/#certificate" className='navbar-link' >{t('navbar.srtificate')}</a>
                            </li>
                            <li>
                                <a href="/#partners" className='navbar-link' >{t('navbar.partners')}</a>
                            </li>
                            <li>
                                <a href="/#contact" className='navbar-link' >{t('navbar.contact')}</a>
                            </li>
                        </ul>
                    </Col>
                    <Col md='12' className='social'>
                        <a href='https://t.me/Dora_Linecompany' className='telegram'>
                            <img src={tele} alt="" />
                            <h4>Telegram</h4>
                        </a>
                        <a href='https://www.instagram.com/planbaby_doraline' className='instagram'>
                            <img src={insta} alt="" />
                            <h4>Instagram</h4>
                        </a>
                    </Col>
                    <Col sm='12' className='social1'>
                        <p className='p'>© 2021. Doraline</p>
                        <p className='p'>Website made by Logo group</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
